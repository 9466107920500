.loginWidth {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .loginWidth {
        width: 100%;
    }
}

