.webapp {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;
    height: calc(100vh - 68px);
    overflow-y: auto !important;
    
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webapp--iframe {
    width: 50%;
    height: 100%;
}