.hero--hero {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 68px);
    gap: 15px;
    color: white;
}

.hero--video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: calc(100vh - 52px);
    width: auto;
    height: auto;
    z-index: -100;
    background-size: cover;
    object-fit: cover;
    overflow: hidden;
    max-width: fit-content;
    height: calc(100vh - 52px);
    background-color: rgba(0, 0, 0, 0.5);
}

.hero--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 52px);
    background-color: rgba(0, 0, 0, 0.5);
    
}

.hero--text {
    position: relative;
    top: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /* z-index: 100; */
}



.hero--button {

    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: black;
    background: whitesmoke;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.hero--button:before {
    content: "";
    background: linear-gradient(45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.hero--button:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    left: 0;
    top: 0;
    border-radius: 10px;
}